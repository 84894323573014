*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: rgba(0, 0, 0, 0);
  background-image: none;
}

:-moz-focusring {
  outline: auto ;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::-ms-input-placeholder, textarea::-ms-input-placeholder {
  opacity: 1;
  color: #9ca3af;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, .5);
  --tw-ring-offset-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-ring-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow-colored: 0 0 rgba(0, 0, 0, 0);
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.top-12 {
  top: 3rem;
}

.left-12 {
  left: 3rem;
}

.bottom-12 {
  bottom: 3rem;
}

.right-64 {
  right: 16rem;
}

.top-1\/2 {
  top: 50%;
}

.left-1\/2 {
  left: 50%;
}

.top-2\/3 {
  top: 66.6667%;
}

.left-2\/3 {
  left: 66.6667%;
}

.left-auto {
  left: auto;
}

.right-2\/3 {
  right: 66.6667%;
}

.top-0 {
  top: 0;
}

.top-\[50vh\] {
  top: 50vh;
}

.left-\[7\.5\%\] {
  left: 7.5%;
}

.top-\[80\%\] {
  top: 80%;
}

.left-\[83\%\] {
  left: 83%;
}

.right-0 {
  right: 0;
}

.top-3\/4 {
  top: 75%;
}

.left-3\/4 {
  left: 75%;
}

.top-\[65\%\] {
  top: 65%;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-30 {
  z-index: 30;
}

.z-50 {
  z-index: 50;
}

.m-auto {
  margin: auto;
}

.mx-10 {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mx-20 {
  margin-left: 5rem;
  margin-right: 5rem;
}

.mr-\[190px\] {
  margin-right: 190px;
}

.ml-\[40px\] {
  margin-left: 40px;
}

.-ml-\[192px\] {
  margin-left: -192px;
}

.-ml-\[0px\] {
  margin-left: 0;
}

.-mr-\[192px\] {
  margin-right: -192px;
}

.-mt-\[50px\] {
  margin-top: -50px;
}

.-ml-\[30px\] {
  margin-left: -30px;
}

.-mt-\[105px\] {
  margin-top: -105px;
}

.-mt-\[180px\] {
  margin-top: -180px;
}

.-mr-\[160px\] {
  margin-right: -160px;
}

.-mt-\[150px\] {
  margin-top: -150px;
}

.-ml-\[240px\] {
  margin-left: -240px;
}

.-mt-\[35px\] {
  margin-top: -35px;
}

.-mr-\[340px\] {
  margin-right: -340px;
}

.-mt-\[30px\] {
  margin-top: -30px;
}

.-mr-\[215px\] {
  margin-right: -215px;
}

.-mt-\[320px\] {
  margin-top: -320px;
}

.-ml-\[60px\] {
  margin-left: -60px;
}

.mt-\[13px\] {
  margin-top: 13px;
}

.-mr-\[260px\] {
  margin-right: -260px;
}

.-mt-\[10px\] {
  margin-top: -10px;
}

.-mr-\[360px\] {
  margin-right: -360px;
}

.-mt-\[301px\] {
  margin-top: -301px;
}

.-mt-\[302px\] {
  margin-top: -302px;
}

.-ml-\[20px\] {
  margin-left: -20px;
}

.-mt-\[300px\] {
  margin-top: -300px;
}

.mt-8 {
  margin-top: 2rem;
}

.-mt-4 {
  margin-top: -1rem;
}

.mt-2 {
  margin-top: .5rem;
}

.-ml-\[10rem\] {
  margin-left: -10rem;
}

.mt-60 {
  margin-top: 15rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mt-0 {
  margin-top: 0;
}

.mt-\[42px\] {
  margin-top: 42px;
}

.ml-\[10rem\] {
  margin-left: 10rem;
}

.-mt-\[10rem\] {
  margin-top: -10rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mb-20 {
  margin-bottom: 5rem;
}

.mt-auto {
  margin-top: auto;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}

.aspect-square {
  aspect-ratio: 1 / 1;
}

.h-full {
  height: 100%;
}

.h-auto {
  height: auto;
}

.h-40 {
  height: 10rem;
}

.h-screen {
  height: 100vh;
}

.h-\[640px\] {
  height: 640px;
}

.h-\[383px\] {
  height: 383px;
}

.h-\[709px\] {
  height: 709px;
}

.min-h-screen {
  min-height: 100vh;
}

.w-full {
  width: 100%;
}

.min-w-\[21rem\] {
  min-width: 21rem;
}

.max-w-\[65\%\] {
  max-width: 65%;
}

.max-w-\[100\%\] {
  max-width: 100%;
}

.max-w-\[75\%\] {
  max-width: 75%;
}

.max-w-\[40rem\] {
  max-width: 40rem;
}

.max-w-\[77\%\] {
  max-width: 77%;
}

.max-w-\[95\%\] {
  max-width: 95%;
}

.max-w-\[85\%\] {
  max-width: 85%;
}

.max-w-\[45rem\] {
  max-width: 45rem;
}

.max-w-\[90\%\] {
  max-width: 90%;
}

.max-w-\[50rem\] {
  max-width: 50rem;
}

.max-w-\[88rem\] {
  max-width: 88rem;
}

.max-w-\[92rem\] {
  max-width: 92rem;
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.translate-x-\[0px\] {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.-translate-x-3\/4 {
  --tw-translate-x: -75%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.-translate-y-3\/4 {
  --tw-translate-y: -75%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.-rotate-\[4deg\] {
  --tw-rotate: -4deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

@keyframes bounce {
  0%, 100% {
    animation-timing-function: cubic-bezier(.8, 0, 1, 1);
    transform: translateY(-25%);
  }

  50% {
    animation-timing-function: cubic-bezier(0, 0, .2, 1);
    transform: none;
  }
}

.animate-\[bounce_1s_ease\] {
  animation: bounce 1s;
}

.cursor-pointer {
  cursor: pointer;
}

.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-4 {
  gap: 1rem;
}

.space-x-14 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(3.5rem * var(--tw-space-x-reverse) );
  margin-left: calc(3.5rem * calc(1 - var(--tw-space-x-reverse) ) );
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.border-8 {
  border-width: 8px;
}

.border-freiburg-red {
  --tw-border-opacity: 1;
  border-color: rgb(213 101 77 / var(--tw-border-opacity) );
}

.bg-freiburg-red {
  --tw-bg-opacity: 1;
  background-color: rgb(213 101 77 / var(--tw-bg-opacity) );
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity) );
}

.bg-freiburg-yellow {
  --tw-bg-opacity: 1;
  background-color: rgb(221 175 39 / var(--tw-bg-opacity) );
}

.bg-freiburg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(236 236 231 / var(--tw-bg-opacity) );
}

.bg-contain {
  background-size: contain;
}

.bg-cover {
  background-size: cover;
}

.bg-center {
  background-position: center;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.fill-current {
  fill: currentColor;
}

.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

.object-top {
  object-position: top;
}

.object-center {
  object-position: center;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-40 {
  padding-left: 10rem;
  padding-right: 10rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.pb-40 {
  padding-bottom: 10rem;
}

.pt-10 {
  padding-top: 2.5rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.font-ZwoPro {
  font-family: ZwoPro, sans-serif;
}

.font-Monument {
  font-family: Monument, sans-serif;
}

.text-\[93px\] {
  font-size: 93px;
}

.text-\[110px\] {
  font-size: 110px;
}

.text-\[58px\] {
  font-size: 58px;
}

.text-\[42px\] {
  font-size: 42px;
}

.text-\[34px\] {
  font-size: 34px;
}

.text-7xl {
  font-size: 4.5rem;
  line-height: 1;
}

.text-\[38px\] {
  font-size: 38px;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-9xl {
  font-size: 8rem;
  line-height: 1;
}

.text-\[300px\] {
  font-size: 300px;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.uppercase {
  text-transform: uppercase;
}

.leading-tight {
  line-height: 1.25;
}

.leading-\[40px\] {
  line-height: 40px;
}

.leading-\[1\.25em\] {
  line-height: 1.25em;
}

.leading-\[45px\] {
  line-height: 45px;
}

.leading-snug {
  line-height: 1.375;
}

.leading-\[1\.1em\] {
  line-height: 1.1em;
}

.text-freiburg-white {
  --tw-text-opacity: 1;
  color: rgb(236 236 231 / var(--tw-text-opacity) );
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity) );
}

.text-freiburg-red {
  --tw-text-opacity: 1;
  color: rgb(213 101 77 / var(--tw-text-opacity) );
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.opacity-50 {
  opacity: .5;
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-150 {
  transition-duration: .15s;
}

.duration-300 {
  transition-duration: .3s;
}

@font-face {
  font-family: Monument;
  font-weight: 600;
  src: url("MonumentExtended-Bold.4d53ab71.otf");
}

@font-face {
  font-family: Monument;
  font-weight: 800;
  src: url("MonumentExtended-Ultrabold.6901c97a.otf");
}

@font-face {
  font-family: ZwoPro;
  font-weight: 400;
  src: url("ZwoPro.cc2f3268.otf");
}

@font-face {
  font-family: ZwoPro;
  font-weight: 600;
  src: url("ZwoPro-Bold.4400a828.otf");
}

@font-face {
  font-family: ZwoPro;
  font-weight: 600;
  font-style: italic;
  src: url("ZwoPro-BoldItalic.8612f71e.otf");
}

@font-face {
  font-family: ZwoPro;
  font-weight: 400;
  font-style: italic;
  src: url("ZwoPro-Italic.bd168097.otf");
}

@keyframes bounce {
  0% {
    opacity: 0;
    transform: scale(.3);
  }

  50% {
    opacity: 1;
    transform: scale(1.05);
  }

  70% {
    transform: scale(.9);
  }

  100% {
    transform: scale(1);
  }
}

.hover\:text-freiburg-yellow:hover {
  --tw-text-opacity: 1;
  color: rgb(221 175 39 / var(--tw-text-opacity) );
}

/*# sourceMappingURL=index.689a6f9b.css.map */
