@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "Monument";
    font-weight: 600;
    src: url("./fonts/Monument/MonumentExtended-Bold.otf");
}
@font-face {
    font-family: "Monument";
    font-weight: 800;
    src: url("./fonts/Monument/MonumentExtended-Ultrabold.otf");
}

@font-face {
    font-family: "ZwoPro";
    font-weight: 400;
    src: url("./fonts/ZwoPro/ZwoPro.otf");
}
@font-face {
    font-family: "ZwoPro";
    font-weight: 600;
    src: url("./fonts/ZwoPro/ZwoPro-Bold.otf");
}
@font-face {
    font-family: "ZwoPro";
    font-weight: 600;
    font-style: italic;
    src: url("./fonts/ZwoPro/ZwoPro-BoldItalic.otf");
}
@font-face {
    font-family: "ZwoPro";
    font-weight: 400;
    font-style: italic;
    src: url("./fonts/ZwoPro/ZwoPro-Italic.otf");
}

@keyframes bounce {
    0% {
        opacity: 0;
        transform: scale(.3);
    }
    50% {
        opacity: 1;
        transform: scale(1.05);
    }
    70% {
        transform: scale(.9);
    }
    100% {
        transform: scale(1);
    }
}